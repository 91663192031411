/* General styling */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

/* Header styles */
.App-header {
  /* Assuming you have an image you want to use in the public folder */
  background-image: url('../public/header-image.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 40px 20px;
  position: relative;
}

/* Semi-transparent overlay for better text contrast on the header image */
.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for text visibility */
  z-index: 1;
}

/* Ensuring header text is visible and legible */
.App-header h1,
.App-header p {
  position: relative;
  z-index: 2; /* Above the overlay */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

/* Main content area */
main {
  max-width: 800px; /* Adjust based on your preference */
  margin: auto;
  text-align: left;
}

/* Individual sections for a cleaner look */
section {
  background: #fff; /* White background for the content */
  border-radius: 8px;
  padding: 20px;
}



/* Section headers */
section h2 {
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Post and content lists */
section ul {
  list-style: none; /* Remove default list styling */
  padding: 0;
}

section li {
  padding: 10px 0;
  border-top: 1px solid #eee;
}

section li:first-child {
  border-top: none;
}

/* Links should stand out */
a {
  color: #4285f4;
  text-decoration: none;
}

/* Search container styles */
.search-container {
  margin: 50px;
}

.search-bar {
  margin: 10px;
  padding: 10px 20px;
  width: 80%;
  max-width: 500px;
  border-radius: 20px;
  border: 1px solid #ddd;
  font-size: 16px;
  position: relative; /* Add relative positioning */
  z-index: 10; /* Ensure it's above other content */
  margin-top: -50px; /* Adjust this value to bring the search bar down from the header */

}

.search-button {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  cursor: pointer;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Position it on top of the page content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Above everything else */
}


.search-button:hover {
  background-color: #357ae8;
}

.search-button.loading {
  background-color: #999; /* Grayed out */
  cursor: default;
}

.search-button:disabled {
  background-color: #999; /* Grayed out */
  cursor: not-allowed;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .search-bar,
  .search-button {
    width: 90%;
  }

  main {
    padding: 10px;
  }
}
/* Adjust the main content area so it doesn't overlap with the header */
.main-content {
  margin-top: 20px; /* Adjust this value so the main content starts below the header */
}

/* Hide the shadow when there's no content */
.main-content-empty {
  box-shadow: none;
}
/* Additional styles for when the content is not empty */
.content-section {
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
}
/* ... existing styles */

.actions {
  text-align: center;
  padding: 20px;
}

.action-button {
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: #357ae8;
}
/* ... existing styles */

.icon-actions {
  text-align:right;
  margin-top: 20px;
  margin:30px;
}

.icon-button {
  cursor: pointer;
  margin: 0 10px;
  font-size: 24px; /* Adjust size as needed */
  color: #000; /* Use your theme color */
  transition: color 0.2s;
}

.icon-button:hover {
  color: #357ae8; /* Darker shade for hover effect */
}

/* Adjust this based on your layout needs */
.content-section {
  position: relative;
  padding-bottom: 50px; /* Add space for icons */
}

/* ... rest of your styles */
